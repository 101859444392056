import ApiService from "@/common/api.service";

export default {
  getExams(data) {
    return ApiService.query("/exams", data);
  },
  getExam(examId) {
    return ApiService.post(`/exams/${examId}/purchase`);
  },
  getResults(userID, data) {
    return ApiService.query(`/users/${userID}/user-exams`,data);
  },
  getUserExam(userExamId) {
    return ApiService.post(`/user-exams/${userExamId}`);
  }
};
